import React from "react";

function Footer() {
    return (
        <footer className="footer" id="footer">
            Footer
        </footer>
    );
}

export default Footer;