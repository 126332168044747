import React from "react";

function Contact() {
    return (
        <>
        <h1>Contact</h1>
        <p>Hello World</p>
        </>
        
    );
}

export default Contact;