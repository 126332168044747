import React from "react";

function About() {
    return (
        <>
        <h1>About</h1>
        <p>Hello World</p>
        </>
        
    );
}

export default About;